// public
import React from "react";
import styled from "styled-components";

// local
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Logo from "../components/Logo";
import Nav from "../components/Nav";

const Container = styled.div`
  margin: 2rem 0;
`;

const BookmarkletLink = styled.a`
  padding: 0.5rem;
  background: #eee;

  &:hover {
    outline: 2px solid #66c0ec;
  }
`;

export default function Bookmarklet(): JSX.Element {
  const bookmarklet =
    'javascript:(()=>{const t=new URL(window.location.href);if(/(^|\\.)textise\\.net$/.test(t.host)&&"/showText.aspx"===t.pathname){const o=t.searchParams.get("strURL");if(o){const t=decodeURIComponent(o);t&&(window.location.href=t)}return}const o=window.location.href;o&&(window.location.href=`https://www.textise.net/showText.aspx?strURL=${encodeURIComponent(o)}`)})();';

  return (
    <Layout>
      <SEO />
      <Logo />
      <Nav />
      <Container>
        <p>
          The Textise bookmarklet is an easy way to flip in and out of text-only
          mode. Drag the link below in to the bookmarks toolbar of your browser
          and you&apos;re done!
        </p>
        <p>
          To use the bookmarklet, just click it to convert a page to text or, if
          you&apos;re already on a Textised page, click to view the original
          page.
        </p>
        <p>
          <BookmarkletLink href={bookmarklet}>Textise</BookmarkletLink> &#x3C;-
          drag this to your bookmarks bar
        </p>
        <p>
          <em>
            Internet Explorer users: If you can&apos;t drag the grey box,
            right-click it and select &quot;Add to Favourites...&quot;, then
            select &quot;Favourites Bar&quot; from the &quot;Create in&quot;
            drop-down.
          </em>
        </p>
      </Container>
    </Layout>
  );
}
